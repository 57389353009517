import React from 'react';
import { FeatureInfo } from './constants';
import * as styles from './features.module.scss';

interface FeatureProps {
  featureInfo: FeatureInfo;
}

const Feature = ({ featureInfo: { image, title, desc } }: FeatureProps) => (
  <div className={styles.card}>
    <div>
      {image && <img src={image} />}
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.desc}>{desc}</div>
    </div>
  </div>
);

export default Feature;
