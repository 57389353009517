import { Seo } from '@/components';
import { buttonEvents } from '@/constants/analytics';
import FUNCIONALIDADES from '@/constants/funcionalidades';
import { IntegracaoAlexaDetails } from '@/constants/pageDetails';
import ImagesProvider from '@/contexts/images';
import { Root } from '@/layouts';
import { ConhecaMais, Detail, Hero } from '@/layouts/Funcionalidades';
import Features from '@/layouts/IntegracaoAlexa/Features/index';
import Steps from '@/layouts/IntegracaoAlexa/Steps';
import React from 'react';

const schema = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      name: 'Home',
      item: 'https://www.simplesdental.com/',
      position: 1,
    },
    {
      '@type': 'ListItem',
      name: 'Agenda integrada à Alexa',
      position: 2,
    },
  ],
};

const IntegracaoAlexa = () => {
  return (
    <ImagesProvider>
      <Seo
        title="A revolução odontológica chegou com a sua Alexa"
        description="Tenha na rotina a agenda ao comando da sua voz. Não há nada mais inovador e prático que organizar seu dia com Simples Dental e Alexa com você."
        schema={schema}
      />
      <Root
        variant="light"
        hero={
          <Hero
            title="A revolução odontológica chegou com a sua Alexa"
            desc="Tenha na rotina a agenda ao comando da sua voz. Não há nada mais inovador e prático que organizar seu dia com Simples Dental e Alexa com você."
            subtitle="ÚLTIMAS NOVIDADES"
            gaMetadata={{ buttonEvent: buttonEvents.integracaoAlexa }}
            alt={FUNCIONALIDADES.integracaoAlexa.name}
            img="agenda-integrada-a-alexa-simples-dental"
            imageTitle="Agenda integrada à Alexa Simples Dental"
            width={952}
            height={592}
            type="png"
          />
        }
      >
        {IntegracaoAlexaDetails.map((i, index) => (
          <Detail item={i} index={index} key={index} />
        ))}

        <Features />

        <Steps />

        <ConhecaMais
          page={FUNCIONALIDADES.integracaoAlexa.name}
          gaMetadata={{
            saibaMaisButtonEvent: buttonEvents.integracaoAlexa,
          }}
          skip={[FUNCIONALIDADES.marketingVendas.name]}
        />
      </Root>
    </ImagesProvider>
  );
};

export default IntegracaoAlexa;
