import imagePasso1 from '@/assets/images/tela-alexa-simples-dental-passo1.png';
import imagePasso2 from '@/assets/images/tela-alexa-simples-dental-passo2.png';
import imagePasso3 from '@/assets/images/tela-alexa-simples-dental-passo3.png';
import imagePasso4 from '@/assets/images/tela-alexa-simples-dental-passo4.png';
import imagePasso5 from '@/assets/images/tela-alexa-simples-dental-passo5.png';
import imagePasso6 from '@/assets/images/tela-alexa-simples-dental-passo6.png';

export interface StepInfo {
  id: number;
  image: string;
  title: string;
  desc: string;
}

export const stepInfos: StepInfo[] = [
  {
    id: 1,
    image: imagePasso1,
    title: 'Passo 1',
    desc: 'No aplicativo Alexa, vá até o menu “Mais” > “Skills e Jogos” e pesquise por Simples Dental.',
  },
  {
    id: 2,
    image: imagePasso2,
    title: 'Passo 2',
    desc: 'Toque na opção "Ativar para Uso" nesta página de skill do Simples Dental, você será direcionado para a tela de login.',
  },
  {
    id: 3,
    image: imagePasso3,
    title: 'Passo 3',
    desc: 'Faça login com a conta Simples Dental que deseja conectar com a Alexa.',
  },
  {
    id: 4,
    image: imagePasso4,
    title: 'Passo 4',
    desc: 'Caso possua mais de uma clínica, escolha sua conta que deseja controlar via Alexa.',
  },

  {
    id: 5,
    image: imagePasso5,
    title: 'Passo 5',
    desc: ' Prossiga com a tela das permissões necessárias.',
  },
  {
    id: 6,
    image: imagePasso6,
    title: 'Passo 6',
    desc: 'E pronto, você já pode começar! Use para se organizar e faça seu dia a dia ser mais leve e divertido.',
  },
];
