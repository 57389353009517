import alexaFeature from '@/assets/images/alexa-feature.png';

export interface FeatureInfo {
  id: number;
  title: string;
  desc: string;
  image?: string;
}

const featureInfos: FeatureInfo[] = [
  {
    id: 1,
    image: alexaFeature,
    title: '"Alexa, qual minha próxima consulta?"',
    desc: 'Sua próxima consulta será amanhã às 17 horas com o paciente Lucas.',
  },
  {
    id: 2,
    title: 'É simples ser prático e inovador',
    desc: '*Disponível em todos os planos',
  },
  {
    id: 3,
    image: alexaFeature,
    title: '"Alexa, quais consultas tenho no dia 12 de agosto?"',
    desc: 'Você possui 8 consultas marcadas no dia 12 de Agosto. Gostaria de ouvir detalhes sobre essas consultas?',
  },
  {
    id: 4,
    image: alexaFeature,
    title: '"Alexa, qual o próximo horário livre?"',
    desc: 'Seu próximo horário livre na agenda é hoje às 10 horas.',
  },
  {
    id: 5,
    title: 'Sua agenda agora fala com você',
    desc: 'Conecte sua Alexa com o Simples Dental!',
  },
  {
    id: 6,
    image: alexaFeature,
    title: '"Alexa, qual o resumo do meu dia?"',
    desc: 'Olá, este é o resumo da sua agenda para hoje: 3 consultas agendadas, 2 consultas confirmadas, 1 consulta cancelada, 1 compromisso e 2 aniversariantes. Que seu dia seja excelente!',
  },
];

export default featureInfos;
