import React from 'react';
import { Container } from 'react-bootstrap';
import * as styles from './steps.module.scss';
import { stepInfos } from './constants';

const Steps = () => (
  <Container className={styles.container}>
    <h2 className={styles.title}>Adicione a skill Simples Dental no app Alexa para começar!</h2>

    <div className={styles.subtitle}>
      Um passo a passo prático para lhe ajudar a instalar a Skill na Alexa e começar a utilizar
      rapidamente.
    </div>

    <div className={styles.wrapper}>
      {stepInfos.map((i, index) => (
        <>
          <div key={index} className={styles.card}>
            <img src={i.image} />
            <p className={styles.step}>{i.title}</p>
            <div className={styles.desc}>{i.desc}</div>
          </div>
          {index !== 2 && index < 5 && <hr />}
        </>
      ))}
    </div>
  </Container>
);

export default Steps;
