import React from 'react';
import { Container } from 'react-bootstrap';
import * as styles from './features.module.scss';
import constants from './constants';
import Feature from './Feature';
import Button from '@/components/Button';
import { trackButtonClick } from '@/services/analytics';
import { buttonEvents, integracaoAlexaButtonNames } from '@/constants/analytics';
import { navigate } from 'gatsby';
import { routes } from '@/constants/routes';

const Features = () => {
  const handleTestarGratis = () => {
    navigate(routes.cadastro);
    trackButtonClick({
      event: buttonEvents.integracaoAlexa,
      buttonName: integracaoAlexaButtonNames.testarGratisFeatures,
    });
  };

  return (
    <div className={styles.container}>
      <Container className={styles.wrapper}>
        <div className={styles.subtitle}>ALEXA NO SIMPLES DENTAL</div>
        <div className={styles.title}>
          <span>Uma rotina</span>
          <span className={styles.titleBlue}> simples e divertida!</span>
        </div>
        <div className={styles.features}>
          {constants.map((i, index) => (
            <Feature key={index} featureInfo={i} />
          ))}
        </div>
        <Button text="Quero testar" variant="orange" onClick={handleTestarGratis} />
      </Container>
    </div>
  );
};

export default Features;
